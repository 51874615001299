import { dataAtom, dataComposer } from "@frend-digital/centra";

import type { CentraProduct } from "../atomicSetup";
import { baseAtomics } from "../atomicSetup";
import {
  get30DayComplianceKey,
  getAvailability,
  getComingSoon,
  getFaq,
  getGender,
  getLabels,
  getMedia,
  getProductFit,
  getProductType,
  getShortDesc,
  getSizeGuide,
  getSoldOut,
  getSpecs,
  getSwatch,
} from "../localAtomics";
import getProductGallery from "./formatProductCards/getGallery";
const {
  getDisplayPriceWithSale,
  getName,
  getUri,
  getProductBreadcrumbs,
  getVariantName,
  getProduct,
  getSku,
  getProductSizes,
  getDescriptionHtml,
} = baseAtomics;

export const formatVariant = (product: CentraProduct) => {
  const name = dataAtom(getName, 2)(product);
  const itemId = dataAtom(getProduct, 2)(product);
  const variantName = dataAtom(getVariantName, 2)(product);
  const breadcrumbs = dataAtom(getProductBreadcrumbs, 2)(product);
  const prices = dataAtom(getDisplayPriceWithSale, 2)(product);
  const uri = dataAtom(getUri, 2)(product);
  const description = dataAtom(getDescriptionHtml, 2)(product);
  const shortDescription = dataAtom(getShortDesc, 2)(product);
  const sku = dataAtom(getSku, 2)(product);
  const sizes = dataAtom(getProductSizes, 2)(product);
  const media = dataAtom(getMedia, 2)(product);
  const gender = dataAtom(getGender, 2)(product);
  const sizeguide = dataAtom(getSizeGuide, 2)(product);
  const faq = dataAtom(getFaq, 2)(product);
  const specs = dataAtom(getSpecs, 2)(product);
  const comingSoon = dataAtom(getComingSoon, 2)(product);
  const labels = dataAtom(getLabels, 2)(product);
  const swatch = dataAtom(getSwatch, 2)(product);
  const gallery = getProductGallery(product);
  const availability = dataAtom(getAvailability, 2)(product);
  const soldOut = dataAtom(getSoldOut, 2)(product);
  const fit = dataAtom(getProductFit, 2)(product);
  const type = dataAtom(getProductType, 2)(product);
  const isSalePrice = dataAtom(get30DayComplianceKey, 2)(product);

  return dataComposer({
    name,
    breadcrumbs,
    variantName,
    prices,
    uri,
    itemId,
    media,
    description,
    shortDescription,
    sku,
    sizes,
    sizeguide,
    specs,
    gallery,
    labels,
    swatch,
    availability,
    gender,
    faq,
    comingSoon,
    soldOut,
    fit,
    type,
    isSalePrice,
  });
};

export type VariantType = ReturnType<typeof formatVariant>;
